import classNames from 'classnames';
import { FC } from 'react';
import { fallbackText } from 'ui/utils/fallback-text';
import styles from './RichText.module.scss';

interface RichTextProps {
  htmlText?: string;
  className?: string;
  dataTestId?: string;
}

export const RichText: FC<RichTextProps> = props => {
  const { htmlText, className, dataTestId } = props;
  const safeText = fallbackText(htmlText);
  return (
    <div
      className={classNames(styles.root, className)}
      dangerouslySetInnerHTML={{ __html: safeText }}
      data-testid={dataTestId}
    />
  );
};

RichText.defaultProps = {
  dataTestId: 'rich-text',
};
